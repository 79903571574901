import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Button from "../../button/Button";

import "./modal-view-actions.scss";
import { debugPrint } from "../../../services/logging/logger";

const ModalViewActions = ({
  disabled = false,
  cancelDisabled = false,
  confirmDisabled = false,
  cancelButton = null,
  confirmButton = null,
  cancelIcon = null,
  confirmIcon = null,
  onCancel = null,
  onConfirm = null,
}) => {
  const { t } = useTranslation("components");

  if (!onCancel && !onConfirm) {
    // eslint-disable-next-line no-console
    debugPrint("ModalViewActions: No onCancel or onConfirm function provided", console.error);
  }

  return (
    <div className="modal-view-actions" icon={cancelIcon}>
      {onCancel && (
        <Button theme="subtle" disabled={disabled || cancelDisabled} onClick={onCancel}>
          {cancelButton || t("modalViewActions.cancel")}
        </Button>
      )}
      {onConfirm && (
        <Button
          theme="primary"
          disabled={disabled || confirmDisabled}
          onClick={onConfirm}
          icon={confirmIcon}
        >
          {confirmButton || t("modalViewActions.confirm")}
        </Button>
      )}
    </div>
  );
};

ModalViewActions.propTypes = {
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  cancelButton: PropTypes.string,
  confirmButton: PropTypes.string,
  cancelIcon: PropTypes.element,
  confirmIcon: PropTypes.element,
  disabled: PropTypes.bool,
  cancelDisabled: PropTypes.bool,
  confirmDisabled: PropTypes.bool,
};

export default ModalViewActions;
