import * as backendService from "../backendService";
import { fromCamelToSnake, fromSnakeToCamel } from "../../utils/letterCasingUtils";

export const list = async ({ organizationId }) => {
  const url = `/organizations/${organizationId}/tax-rates`;
  const response = await backendService.get({ url });

  return { data: fromSnakeToCamel(response.data) };
};

export const listAccountingTaxCodes = async ({ organizationId }) => {
  const url = `/organizations/${organizationId}/tax-rates/accounting-tax-codes`;
  const response = await backendService.get({ url });

  return { data: fromSnakeToCamel(response.data) };
};

export const create = async ({
  organizationId,
  name,
  taxRate,
  secondaryTaxRate = null,
  taxKind,
  accountingTaxCodeId,
}) => {
  const url = `/organizations/${organizationId}/tax-rates`;
  const response = await backendService.post({
    url,
    data: fromCamelToSnake({ name, taxRate, secondaryTaxRate, taxKind, accountingTaxCodeId }),
  });

  return { data: fromSnakeToCamel(response.data) };
};

export const update = async ({
  organizationId,
  id,
  name,
  taxRate,
  secondaryTaxRate = null,
  taxKind,
  accountingTaxCodeId,
}) => {
  const url = `/organizations/${organizationId}/tax-rates/${id}`;
  const response = await backendService.patch({
    url,
    data: fromCamelToSnake({ name, taxRate, secondaryTaxRate, taxKind, accountingTaxCodeId }),
  });

  return { data: fromSnakeToCamel(response.data) };
};

export const destroy = async ({ organizationId, id }) => {
  const url = `/organizations/${organizationId}/tax-rates/${id}`;
  const response = await backendService.destroy({ url });

  return { data: fromSnakeToCamel(response.data) };
};
