export function composeDocumentFilterScope(filter) {
  let scope = "";

  if (filter.documentCategoryId) {
    scope += `-documentCategoryId={{${filter.documentCategoryId}}}`;
  }
  if (filter.documentCategoryIds) {
    scope += `-documentCategoryIds[]={{${stringListOrArrayToScopeValue(
      filter.documentCategoryIds,
    )}}}`;
  }
  if (filter.itemCategoryIds) {
    scope += `-itemCategoryIds[]={{${stringListOrArrayToScopeValue(filter.itemCategoryIds)}}}`;
  }
  if (filter.organizationUserIds) {
    scope += `-organizationUserIds[]={{${stringListOrArrayToScopeValue(
      filter.organizationUserIds,
    )}}}`;
  }
  if (filter.noItemCategory) {
    scope += `-noItemCategory=true`;
  }
  if (filter.month) {
    scope += `-month={{${filter.month}}}`;
  }
  if (filter.year) {
    scope += `-year={{${filter.year}}}`;
  }
  if (filter.createdMonth) {
    scope += `-createdMonth={{${filter.createdMonth}}}`;
  }
  if (filter.createdYear) {
    scope += `-createdYear={{${filter.createdYear}}}`;
  }
  if (filter.transactionType) {
    scope += `-transactionType={{${filter.transactionType}}}`;
  }
  if (filter.hashTags) {
    scope += `-hashTags[]={{${filter.hashTags.join(",")}}}`;
  }
  if (filter.reviewStatus) {
    scope += `-reviewStatus={{${filter.reviewStatus}}}`;
  }
  if (filter.reviewStatuses) {
    scope += `-reviewStatuses[]={{${filter.reviewStatuses.join(",")}}}`;
  }
  if (filter.orderBy) {
    scope += `-orderBy={{${filter.orderBy}}}`;
  }
  if (filter.order) {
    let { order } = filter;
    if (filter.order instanceof Array) {
      order = filter.order.join(",");
    }
    scope += `-order={{${order}}}`;
  }
  if (filter.limit) {
    scope += `-limit={{${filter.limit}}}`;
  }
  if (filter.trashed) {
    scope += "-trashed={{true}}";
  }
  if (filter.inputSource) {
    scope += `-inputSource={{${filter.inputSource}}}`;
  }
  if (filter.inputSources) {
    scope += `-inputSources[]={{${filter.inputSources.join(",")}}}`;
  }

  return scope;
}

function stringListOrArrayToScopeValue(arrValue) {
  if (arrValue instanceof Array) {
    return arrValue.join(",");
  }

  return arrValue;
}

export function documentMatchesScope(document, scope) {
  const filter = extractFilterFromScope(scope);
  return documentMatchesFilter(document, filter);
}

export function documentMatchesFilter(document, filter) {
  let isMatch = true;

  const documentTime = new Date(document.documentTime);
  const createdAt = new Date(document.createdAt);

  if (filter.documentCategoryId) {
    isMatch = isMatch && document.documentCategoryId === filter.documentCategoryId;
  }

  if (filter.documentCategoryIds) {
    isMatch = isMatch && filter.documentCategoryIds.includes(document.documentCategoryId);
  }

  if (filter.itemCategoryId) {
    isMatch = isMatch && document.itemCategoryId === filter.itemCategoryId;
  }

  if (filter.itemCategoryIds) {
    isMatch = isMatch && filter.itemCategoryIds.includes(document.itemCategoryId);
  }

  if (filter.noItemCategory) {
    isMatch = isMatch && document.itemCategoryId === null;
  }

  if (filter.organizationUserIds) {
    isMatch = isMatch && filter.organizationUserIds.includes(document.organizationUserId);
  }

  if (filter.month) {
    isMatch = isMatch && documentTime.getMonth() + 1 === parseInt(filter.month, 10);
  }

  if (filter.year) {
    isMatch = isMatch && documentTime.getFullYear() === parseInt(filter.year, 10);
  }

  if (filter.transactionType) {
    isMatch = isMatch && document.transactionType === filter.transactionType;
  }

  if (filter.reviewStatus) {
    isMatch = isMatch && document.reviewStatus === filter.reviewStatus;
  }

  if (filter.reviewStatuses) {
    isMatch = isMatch && filter.reviewStatuses.includes(document.reviewStatus);
  }

  if (filter.createdMonth) {
    isMatch = isMatch && createdAt.getMonth() + 1 === parseInt(filter.createdMonth, 10);
  }

  if (filter.createdYear) {
    isMatch = isMatch && createdAt.getFullYear() === parseInt(filter.createdYear, 10);
  }

  if (filter.inputSource) {
    isMatch = isMatch && document.inputSource === filter.inputSource;
  }

  if (filter.inputSources) {
    isMatch = isMatch && filter.inputSources.includes(document.inputSource);
  }

  if (filter.hashTags) {
    isMatch =
      isMatch &&
      filter.hashTags.every((hashTag) => document.hashTags.map((t) => t.name).includes(hashTag));
  }

  // There are no "in-between" alternatives when it comes to "trashed".
  const filterRequiresTrashed = filter.trashed === true;
  const filterRequiresKept = !filterRequiresTrashed;

  if (filterRequiresTrashed) {
    isMatch = isMatch && document.trashed === true;
  }
  if (filterRequiresKept) {
    isMatch = isMatch && document.trashed === false;
  }

  return isMatch;
}

export function extractFilterFromScope(str) {
  // eslint-disable-next-line prefer-regex-literals
  const regex = /-([a-zA-Z]+(?:\[\])?)=\{\{([^}]+)\}\}/g;
  const matches = [...str.matchAll(regex)];
  const obj = {};

  matches.forEach((match) => {
    const key = match[1];
    const value = typeInferredValue(match[2]);

    if (key.includes("[]")) {
      obj[key.replaceAll("[]", "")] = value.split(",");
    } else {
      obj[key] = value;
    }
  });

  return obj;
}

function typeInferredValue(value) {
  if (value === "true") {
    return true;
  }
  if (value === "false") {
    return false;
  }

  if (Number(value) === parseInt(value, 10)) {
    return parseInt(value, 10);
  }

  return value;
}
