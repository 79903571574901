import React from "react";
import PropTypes from "prop-types";

import "./modal-view-header.scss";

const ModalViewHeader = ({ header }) => {
  if (typeof header === "string") {
    return <h1 className="modal-view-header">{header}</h1>;
  }

  return <div className="modal-view-header">{header}</div>;
};

ModalViewHeader.propTypes = {
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default ModalViewHeader;
