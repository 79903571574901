import React from "react";
import PropTypes from "prop-types";

import "./modal-view-body.scss";

const ModalViewBody = ({ children, className, style }) => (
  <div className={`modal-view-body ${className}`} style={style}>
    {children}
  </div>
);

ModalViewBody.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  style: PropTypes.object,
};

export default ModalViewBody;
