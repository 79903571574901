import * as backendService from "../backendService";
import { fromCamelToSnake, fromSnakeToCamel } from "../../utils/letterCasingUtils";

export const login = async ({ email, password }) => {
  const url = `login/user`;
  const response = await backendService.post({
    url,
    data: fromCamelToSnake({
      email,
      password,
      loginSource: "web",
    }),
  });

  return { data: fromSnakeToCamel(response.data) };
};

export const loginOAuth = async ({
  provider,
  credential,
  userInvitationId = null,
  registerRef = null,
  glParam = null,
  gclidParam = null,
  fbclidParam = null,
  fbpParam = null,
  utm = {},
}) => {
  const url = `login/user/oauth`;
  const response = await backendService.post({
    url,
    data: fromCamelToSnake({
      provider,
      credential,
      userInvitationId,
      loginSource: "web",
      registerParams: {
        registerRef,
        glParam,
        gclidParam,
        fbcParam: fbclidParam,
        fbpParam,
        utm,
      },
    }),
  });

  return { data: fromSnakeToCamel(response.data) };
};

export const loginInfo = async () => {
  const url = `login/user/info`;
  const response = await backendService.get({ url });

  return { data: fromSnakeToCamel(response.data) };
};

export const loginTokenExchange = async ({ userId, token }) => {
  const url = `login/user/token-exchange`;
  const response = await backendService.post({ url, data: fromCamelToSnake({ userId, token }) });

  return { data: fromSnakeToCamel(response.data) };
};

export const acceptInviteCurrentUser = async ({ userInvitationId, inviteCode }) => {
  const url = `login/user/accept-invite-current-user`;

  const response = await backendService.post({
    url,
    data: fromCamelToSnake({ userInvitationId, inviteCode }),
  });

  return { data: fromSnakeToCamel(response.data) };
};

export const acceptInvite = async ({
  userInvitationId,
  inviteCode,
  email,
  password,
  passwordConfirm,
  acceptMode = "register",
}) => {
  const url = `login/user/accept-invite`;

  const response = await backendService.post({
    url,
    data: fromCamelToSnake({
      userInvitationId,
      inviteCode,
      email,
      password,
      passwordConfirm,
      acceptMode,
    }),
  });

  return { data: fromSnakeToCamel(response.data) };
};

export const verifyEmail = async ({ userIdentityId, verifyCode }) => {
  const url = `login/user/verify-email`;

  try {
    const response = await backendService.post({
      url,
      data: fromCamelToSnake({
        userIdentityId,
        verifyCode,
      }),
    });

    // Successfully posted data, return the camelCased response
    return { data: fromSnakeToCamel(response.data) };
  } catch (error) {
    // Check if the error is due to HTTP 400
    if (error.response && error.response.status === 400) {
      // Return normally with the response data contained in the error object
      return { data: fromSnakeToCamel(error.response.data) };
    }

    // If the error is not due to HTTP 400, re-throw it to handle it elsewhere or log it
    throw error;
  }
};

export const initResetPassword = async ({ email }) => {
  const url = "login/user/init-reset-password";
  const response = await backendService.post({ url, data: fromCamelToSnake({ email }) });
  return { data: fromSnakeToCamel(response.data) };
};

export const resetPassword = async ({ userIdentityId, token, password, passwordConfirmation }) => {
  const url = "login/user/reset-password";
  const response = await backendService.post({
    url,
    data: fromCamelToSnake({ userIdentityId, token, password, passwordConfirmation }),
  });
  return { data: fromSnakeToCamel(response.data) };
};
