import _ from "lodash";

export const fromSnakeToCamel = (data) => {
  if (_.isArray(data)) {
    return _.map(data, fromSnakeToCamel);
  }

  if (_.isObject(data)) {
    return _(data)
      .mapKeys((v, k) => _.camelCase(k))
      .mapValues((v) => fromSnakeToCamel(v))
      .value();
  }

  return data;
};

export const fromCamelToSnake = (data) => {
  if (_.isArray(data)) {
    return _.map(data, fromCamelToSnake);
  }

  if (_.isObject(data)) {
    return _(data)
      .mapKeys((v, k) => _.snakeCase(k))
      .mapValues((v) => fromCamelToSnake(v))
      .value();
  }

  return data;
};

export function capitalizeFirstLetter(val) {
  return String(val).charAt(0).toUpperCase() + String(val).slice(1);
}
