import * as backendService from "../backendService";
import { fromCamelToSnake, fromSnakeToCamel } from "../../utils/letterCasingUtils";

export const list = async ({ organizationId }) => {
  const url = `/organizations/${organizationId}/item-categories`;
  const response = await backendService.get({ url });

  return { data: fromSnakeToCamel(response.data) };
};

export const create = async ({ organizationId, name, categoryCode, transactionType }) => {
  const url = `/organizations/${organizationId}/item-categories`;
  const response = await backendService.post({
    url,
    data: fromCamelToSnake({ name, categoryCode, transactionType }),
  });

  return { data: fromSnakeToCamel(response.data) };
};

export const update = async ({ organizationId, id, categoryCode, name }) => {
  const url = `/organizations/${organizationId}/item-categories/${id}`;
  const response = await backendService.patch({
    url,
    data: fromCamelToSnake({ name, categoryCode }),
  });

  return { data: fromSnakeToCamel(response.data) };
};

export const destroy = async ({ organizationId, id }) => {
  const url = `/organizations/${organizationId}/item-categories/${id}`;
  const response = await backendService.destroy({ url });

  return { data: fromSnakeToCamel(response.data) };
};

export const prepareCsvForImport = async ({ organizationId, file, transactionType }) => {
  const url = `/organizations/${organizationId}/item-categories/import/prepare`;

  const response = await backendService.upload({
    file,
    url,
    transactionType,
  });

  return { data: fromSnakeToCamel(response.data) };
};

export const importItemCategoriesStatus = async ({ organizationId, taskId }) => {
  const url = `/organizations/${organizationId}/item-categories/import/${taskId}/status`;
  const response = await backendService.get({ url });

  return { data: fromSnakeToCamel(response.data) };
};

export const importItemCategories = async ({
  organizationId,
  file,
  replaceExisting,
  hasHeaders,
  transactionType,
}) => {
  const url = `/organizations/${organizationId}/item-categories/import`;

  const response = await backendService.upload({
    file,
    url,
    data: fromCamelToSnake({
      replaceExisting,
      hasHeaders,
      transactionType,
    }),
  });

  return { data: fromSnakeToCamel(response.data) };
};
