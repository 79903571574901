import React, { useState } from "react";
import PropTypes from "prop-types";
import { Trans, useTranslation } from "react-i18next";
import { createRoot } from "react-dom/client";
import { uniqueId } from "lodash";
import ModalView from "../../modal-view/ModalView";

import "./pro-feature-modal.scss";
import ModalViewHeader from "../../modal-view/header/ModalViewHeader";
import ModalViewBody from "../../modal-view/content/ModalViewBody";
import ModalViewActions from "../../modal-view/actions/ModalViewActions";

const ProFeatureModal = ({ onCleanup, messageType = "pro_feature" }) => {
  const { t } = useTranslation("components");
  const [modalOpen, setModalOpen] = useState(true);

  return (
    <ModalView
      className="pro-feature-modal"
      isOpen={modalOpen}
      allowDismiss={true}
      onDismiss={() => {
        setModalOpen(false);
      }}
      onModalHideComplete={() => {
        onCleanup();
      }}
      allowBackgroundScroll={true}
    >
      <ModalViewHeader header={t("proFeatureModal.title")} />

      <ModalViewBody>
        <p>
          {messageType === "pro_feature"
            ? t("proFeatureModal.proOnlyFeature")
            : t("proFeatureModal.quotaDepleted")}
        </p>

        <div className="pro-feature-content">
          <h2>{t("proFeatureModal.subtitle")}</h2>
          <ul className="pro-features-list">
            <li>
              <Trans
                ns="components"
                i18nKey={"proFeatureModal.unlimitedDocuments"}
                components={{ strong: <strong /> }}
              />
            </li>
            <li>
              <Trans
                ns="components"
                i18nKey={"proFeatureModal.inviteUsers"}
                components={{ strong: <strong /> }}
              />
            </li>
            <li>
              <Trans
                ns="components"
                i18nKey={"proFeatureModal.multipleAccounts"}
                components={{ strong: <strong /> }}
              />
            </li>
          </ul>
        </div>
      </ModalViewBody>

      <ModalViewActions
        confirmButton={t("proFeatureModal.getPro")}
        onConfirm={() => {
          window.location.href = "/dashboard/organization/subscription";
        }}
        onCancel={() => setModalOpen(false)}
      />
    </ModalView>
  );
};

ProFeatureModal.propTypes = {
  onCleanup: PropTypes.func,
  messageType: PropTypes.oneOf(["pro_feature", "quota_depleted"]),
};

export function showProFeatureModal({ messageType = "pro_feature" } = {}) {
  const containerId = `pro-feature-modal-${uniqueId()}`;
  const container = document.createElement("div");
  container.setAttribute("id", containerId);
  const child = (
    <ProFeatureModal
      messageType={messageType}
      onCleanup={() => {
        container.remove();
      }}
    />
  );

  const root = createRoot(container);

  root.render(child);

  document.getElementById("overlay-container").appendChild(container);
}

export default ProFeatureModal;
