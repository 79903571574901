import { isArray } from "lodash";
import * as backendService from "../backendService";
import { fromCamelToSnake, fromSnakeToCamel } from "../../utils/letterCasingUtils";
import { getSingleSharePassword } from "./shareCodeService";

export const list = async ({ organizationId, filter }) => {
  const url = `/organizations/${organizationId}/documents`;
  const response = await backendService.get({
    url,
    data: fromCamelToSnake({
      filter: prepareFilterArray(filter),
    }),
  });

  return { data: fromSnakeToCamel(response.data) };
};

export const search = async ({ organizationId, filters }) => {
  const url = `/organizations/${organizationId}/documents/search`;

  const response = await backendService.get({
    url,
    data: {
      search: fromCamelToSnake(filters),
    },
  });

  return { data: fromSnakeToCamel(response.data) };
};

export const count = async ({ organizationId, filter }) => {
  const url = `/organizations/${organizationId}/documents/count`;
  const response = await backendService.get({
    url,
    data: fromCamelToSnake({
      filter: prepareFilterArray(filter),
    }),
  });

  return { data: fromSnakeToCamel(response.data) };
};

export const totalValue = async ({ organizationId, currencyCode, filter }) => {
  const url = `/organizations/${organizationId}/documents/total-value`;
  const response = await backendService.get({
    url,
    data: fromCamelToSnake({
      filter: prepareFilterArray(filter),
      currencyCode,
    }),
  });

  return { data: fromSnakeToCamel(response.data) };
};

export const listMeta = async ({ organizationId }) => {
  const url = `/organizations/${organizationId}/documents/meta`;
  const response = await backendService.get({ url });
  return { data: fromSnakeToCamel(response.data) };
};

export const get = async ({ organizationId, documentId }) => {
  const url = `/organizations/${organizationId}/documents/${documentId}`;
  const response = await backendService.get({ url });

  return { data: fromSnakeToCamel(response.data) };
};

export const getList = async ({ organizationId, documentIds = [] }) => {
  const url = `/organizations/${organizationId}/documents/get-list`;
  const response = await backendService.post({
    url,
    data: fromCamelToSnake({ documentIds }),
  });
  return { data: fromSnakeToCamel(response.data) };
};

export const getChanges = async ({ organizationId, documentId } = {}) => {
  const url = `/organizations/${organizationId}/documents/${documentId}/changes`;
  const response = await backendService.get({ url });
  return { data: fromSnakeToCamel(response.data) };
};

export const create = async ({
  organizationId,
  documentCategoryId,
  itemCategoryId,
  transactionType,
  name,
  documentTime,
}) => {
  const url = `/organizations/${organizationId}/documents`;

  const documentTimeStamp =
    typeof documentTime === "string" ? documentTime : documentTime.toISOString();

  const response = await backendService.post({
    url,
    data: fromCamelToSnake({
      documentCategoryId,
      documentTime: documentTimeStamp,
      itemCategoryId,
      transactionType,
      name,
      inputSource: "web",
    }),
  });

  return { data: fromSnakeToCamel(response.data) };
};

export const upload = async ({ organizationId, documentId, file, index }) => {
  const url = `/organizations/${organizationId}/documents/${documentId}/upload`;

  const response = await backendService.upload({ url, file, data: { index } });

  return { data: fromSnakeToCamel(response.data) };
};

export const deleteFile = async ({ organizationId, documentId, documentFileId }) => {
  const url = `/organizations/${organizationId}/documents/${documentId}/document-files/${documentFileId}`;

  const response = await backendService.destroy({ url });
  return { data: fromSnakeToCamel(response.data) };
};

export const replaceFile = async ({ organizationId, documentId, documentFileId, file }) => {
  const url = `/organizations/${organizationId}/documents/${documentId}/document-files/${documentFileId}/replace`;

  const response = await backendService.upload({ url, file });
  return { data: fromSnakeToCamel(response.data) };
};

export const uploadProcessedFile = async ({
  organizationId,
  documentId,
  documentFileId,
  file,
  filterType = null,
  cropType = null,
  cropPoints = null,
  cwRotations = null,
}) => {
  const url = `/organizations/${organizationId}/documents/${documentId}/document-files/${documentFileId}/processed`;

  const data = {};

  if (filterType) {
    data.filterType = filterType;
  }
  if (cwRotations !== null) {
    data.cwRotations = cwRotations;
  }
  if (cropType) {
    data.cropType = cropType;
    data.cropPoints = cropPoints;
  }

  const response = await backendService.upload({
    url,
    file,
    data: fromCamelToSnake(data),
  });

  return { data: fromSnakeToCamel(response.data) };
};

export const patch = async ({
  organizationId,
  documentId,
  name,
  description,
  documentTime,
  documentCategoryId,
  skipDuplicateCheck,
  itemCategoryId,
  transactionType,
  paymentMethodId,
  referenceNumber,
  customExchangeRate,
  customExchangeRateCurrency,
  clearCustomExchangeRates,
  taxMode,
  tipAmount,
  reviewStatus,
  trashed,
  currencyCode,
  documentValueLines,
  ocrRecognizedFields,
  hashTags,
}) => {
  const url = `/organizations/${organizationId}/documents/${documentId}`;
  const response = await backendService.patch({
    url,
    data: fromCamelToSnake({
      trashed,
      name,
      description,
      documentTime,
      documentCategoryId,
      itemCategoryId,
      paymentMethodId,
      referenceNumber,
      transactionType,
      customExchangeRate,
      customExchangeRateCurrency,
      clearCustomExchangeRates,
      skipDuplicateCheck,
      taxMode,
      tipAmount,
      reviewStatus,
      currencyCode,
      documentValueLines: documentValueLines
        ? documentValueLines.map((documentValueLine) => ({
            id: documentValueLine.id,
            grossValue: documentValueLine.grossValue,
            accountingTaxCodeId: documentValueLine.accountingTaxCodeId,
            clearAccountingTaxCodeId: documentValueLine.clearAccountingTaxCodeId,
            documentValueLineTaxes: documentValueLine.documentValueLineTaxes.map(
              (documentValueLineTax) => ({
                taxAmount: documentValueLineTax.taxAmount,
                taxKind: documentValueLineTax.taxKind,
                customTaxRate: documentValueLineTax.customTaxRate || undefined,
              }),
            ),
          }))
        : undefined,
      ocrRecognizedFields,
      hashTags,
    }),
  });

  return { data: fromSnakeToCamel(response.data) };
};

export const patchMultiple = async (params) => {
  const url = `/organizations/${params.organizationId}/documents/update-multiple`;

  const response = await backendService.patch({
    url,
    data: fromCamelToSnake(params),
  });

  return { data: fromSnakeToCamel(response.data) };
};

export const moveToOrganization = async ({ organizationId, documentId, targetOrganizationId }) => {
  const url = `/organizations/${organizationId}/documents/${documentId}/move-to-organization`;

  const response = await backendService.patch({
    url,
    data: fromCamelToSnake({
      targetOrganizationId,
    }),
  });

  return { data: fromSnakeToCamel(response.data) };
};

export const destroy = async ({ organizationId, documentId, requireTrashed = true }) => {
  const url = `/organizations/${organizationId}/documents/${documentId}`;
  const response = await backendService.destroy({
    url,
    data: fromCamelToSnake({ requireTrashed }),
  });

  return { data: fromSnakeToCamel(response.data) };
};

export const destroyList = async ({ organizationId, documentIds = [], requireTrashed = true }) => {
  const url = `/organizations/${organizationId}/documents/destroy-list`;
  const response = await backendService.post({
    url,
    data: fromCamelToSnake({ documentIds, requireTrashed }),
  });

  return { data: fromSnakeToCamel(response.data) };
};

export const generateShareLink = async ({
  collectionName,
  organizationId,
  filter,
  groupingType,
  expiresInHours,
}) => {
  const url = `/organizations/${organizationId}/documents/share-link`;

  const response = await backendService.post({
    url,
    data: fromCamelToSnake({
      collectionName,
      expiresInHours,
      filter,
      groupingType,
    }),
  });

  return { data: fromSnakeToCamel(response.data) };
};

export const generateSingleDocumentShareLink = async ({
  organizationId,
  documentId,
  validityDays,
  password,
}) => {
  const url = `/organizations/${organizationId}/documents/${documentId}/share-link`;

  const response = await backendService.post({
    url,
    data: fromCamelToSnake({
      publicLinkValidityDays: validityDays,
      publicLinkPassword: password,
    }),
  });

  return { data: fromSnakeToCamel(response.data) };
};

export const listDataForShareLink = async ({ documentShareLinkId, shareCode }) => {
  const url = `/shared/document-share-links/${documentShareLinkId}`;

  const response = await backendService.get({
    url,
    data: fromCamelToSnake({
      shareCode,
    }),
  });

  return { data: fromSnakeToCamel(response.data) };
};

export const validateShareCode = async ({ documentId, shareCode }) => {
  // /api/shared/documents/:id/validate-code
  const url = `/shared/documents/${documentId}/validate-code`;

  const response = await backendService.post({
    url,
    data: fromCamelToSnake({
      shareCode,
    }),
  });

  return { data: fromSnakeToCamel(response.data) };
};

export const getSingleDocumentByShareCode = async ({ documentId, shareCode }) => {
  const url = `/shared/documents/${documentId}?share_code=${shareCode}`;

  let headers = {};

  const password = getSingleSharePassword(shareCode);

  if (password) {
    headers = {
      "X-Document-Share-Password": password,
    };
  }

  const response = await backendService.get({
    url,
    data: fromCamelToSnake({
      shareCode,
    }),
    headers,
  });

  return { data: fromSnakeToCamel(response.data) };
};

export const beginOcrAnalysis = async ({ organizationId, documentFileId }) => {
  const url = `/organizations/${organizationId}/document-files/${documentFileId}/ocr-analysis`;
  const response = await backendService.post({
    url,
    data: fromCamelToSnake({ saveResults: true }),
  });
  return { data: fromSnakeToCamel(response.data) };
};

export const ocrAnalysisStatus = async ({ organizationId, documentFileId, ocrAnalysisTaskId }) => {
  const urlBase = `/organizations/${organizationId}/document-files/${documentFileId}`;
  const url = `${urlBase}/ocr-analysis/${ocrAnalysisTaskId}`;
  const response = await backendService.get({ url });
  return { data: fromSnakeToCamel(response.data) };
};

export const listDuplicates = async ({ organizationId, documentId }) => {
  const url = `/organizations/${organizationId}/documents/${documentId}/list-duplicates`;
  const response = await backendService.get({ url });
  return { data: fromSnakeToCamel(response.data) };
};

function prepareFilterArray(filter) {
  const preparedFilter = { ...filter };

  Object.keys(preparedFilter).forEach((key) => {
    if (isArray(preparedFilter[key])) {
      preparedFilter[key] = preparedFilter[key].join(",");
    }
  });

  return preparedFilter;
}
